import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { isAfter, isBefore, subDays, addDays, format, parseISO } from 'date-fns';
import { Helmet } from 'react-helmet';

const KNOOP = gql`
  query GetKnoop($id: ID!) {
    tak(id: $id) {
      data {
        id
        attributes {
          naam
          knoops {
            data {
              id
              attributes {
                knoopEntry
                eindUur
                startUur
                datum
              }
            }
          }
        }
      }
    }
  }
`;

export default function Deknoop() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(KNOOP, {
    variables: { id: id },
  });

  if (loading) return <p className="textelement knoop">Loading...</p>;
  if (error) return <p className="textelement knoop">Error</p>;

  // Ensure knoops is defined and is an array
  const knoops = data.tak?.data?.attributes?.knoops?.data;

  if (!Array.isArray(knoops)) {
    return (
      <div className="textelement knoop">
        <Helmet>
          <title>
            {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
          </title>
          <meta
            name="description"
            content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
          />
          <meta
            name="keywords"
            content="contact, contacteren, e-mail, scouts121, 121, scouts"
          />
          <meta name="HandheldFriendly" content="true" />
        </Helmet>
        <h1>{data.tak.data.attributes.naam}</h1>

        <p>Oeps, er zijn toekomstige vergaderingen te vinden</p>
      </div>
    );
  }

  const today = new Date();
  const last7Days = subDays(today, 7);
  const next30Days = addDays(today, 30);

  const filteredKnoops = knoops.filter((knoop) => {
    const knoopDate = new Date(knoop.attributes.datum);
    return isAfter(knoopDate, last7Days) && isBefore(knoopDate, next30Days);
  });

  if (filteredKnoops.length === 0) {
    return (
      <div className="textelement knoop">
        <Helmet>
          <title>
            {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
          </title>
          <meta
            name="description"
            content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
          />
          <meta
            name="keywords"
            content="contact, contacteren, e-mail, scouts121, 121, scouts"
          />
          <meta name="HandheldFriendly" content="true" />
        </Helmet>
        <h1>{data.tak.data.attributes.naam}</h1>

        <p>Oeps, er zijn toekomstige vergaderingen te vinden</p>
      </div>
    );
  }

  // Helper function to format time in "24u00" format
  const formatTimeTo24Hour = (timeString) => {
    if (!timeString) return ''; // Return an empty string if timeString is invalid or missing
    
    // Attempt to split and check for valid time format (e.g., "HH:mm:ss" or "HH:mm")
    const timeParts = timeString.split(':');
    
    if (timeParts.length < 2) {
      return 'Invalid time'; // Return fallback if the time format is incorrect
    }
    
    // Safely parse hours and minutes
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    if (isNaN(hours) || isNaN(minutes)) {
      return 'Invalid time'; // Return fallback if parsing fails
    }

    // Return formatted string as "HHuMM"
    return `${hours}u${minutes < 10 ? `0${minutes}` : minutes}`;
  };

  // Helper function to format date in "dd/mm/yyyy" format
  const formatDateToDDMMYYYY = (dateString) => {
    if (!dateString) return ''; // Return empty string if dateString is invalid or missing

    const parsedDate = parseISO(dateString); // Parse the date string as ISO date
    return format(parsedDate, 'dd/MM/yyyy'); // Format the date as "dd/mm/yyyy"
  };

  return (
    <div className="textelement knoop">
      <Helmet>
        <title>
          {data.tak.data.attributes.naam} Knoop | Scouts 121 Oude-God Mortsel
        </title>
        <meta
          name="description"
          content="Wat staat er allemaal te gebeuren deze maand? De leiding heeft voor hun leden weer een programma in elkaar gestoken van ravotten, spelen en knutselen. Niet elke tak doet hetzelfde, natuurlijk niet. Lees daarom het programma na van jouw tak. Groepsleiding | Kapoenen | Welka's | Jojo's | Jonggivers | Givers Vergeet niet om ook de activiteiten in het oog te houden."
        />
        <meta
          name="keywords"
          content="contact, contacteren, e-mail, scouts121, 121, scouts"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
      <h1>{data.tak.data.attributes.naam}</h1>
      {filteredKnoops.map((knoop) => (
        <div key={knoop.id} className="knoop-card">
          <h2>{formatDateToDDMMYYYY(knoop.attributes.datum)}</h2>
          <h3>
            {formatTimeTo24Hour(knoop.attributes.startUur)} -{' '}
            {formatTimeTo24Hour(knoop.attributes.eindUur)}
          </h3>
          <p>{knoop.attributes.knoopEntry}</p>
        </div>
      ))}
    </div>
  );
}
